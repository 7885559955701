.slider {
    &-item {
        height: 575px;
        @media screen and (max-width: 600px) {
            height: 40vh;
        }
        @media screen and (max-width: 500px) {
            height: 200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        &__next,
        &__prev {
            color: $white;
            font-family: swiper-icons;
            font-size: 40px;
            text-transform: none;
            letter-spacing: 0;
            text-transform: none;
            font-variant: initial;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -59vh;
            width: 62px;
            height: 62px;
            border-radius: 100%;
            border: 0;
            cursor: pointer;
            z-index: 2;
            position: relative;
            transition: .25s all;
            &:after {
                content: 'next';
            }
            &:hover {
                color: $primary;
                transition: .25s all;
            }
            @media screen and (max-width: 600px) {
                margin-top: -38vh;
                height: 50px;
                width: 50px;
            }
            @media screen and (max-width: 500px) {
                margin-top: -28vh;
                width: 25px;
                height: 25px;
                &:after {
                    font-size: 25px;
                }
            }
        }
        &__prev {
            &:after {
                content: 'prev';
            }
        }
    }
    &-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 38px auto;
        gap: 11px;
        @media screen and (max-width: 500px) {
            gap: 9px;
            margin: 17px auto 0;
        }
        span {
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background: #C4C4C4;
            transition: .25s all;
            cursor: pointer;
            z-index: 2;
            @media screen and (max-width: 500px) {
                width: 11px;
                height: 11px;
            }
            &:hover {
                background: $primary;
                transition: .25s all;
            }
            &.swiper-pagination-bullet-active {
                background: $primary;
                transition: .25s all;
            }
        }
    }
}