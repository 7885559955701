.banner {
    &-township {
        &__pc {
            height: 562px;
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
        &__mob {
            display: none;
            height: 300px;
            @media screen and (max-width: 500px) {
                display: block;
            }
        }
    }
    &-wrap {
        margin-bottom: 80px;
        @media screen and (max-width: 500px) {
            margin-bottom: 47px;
        }
    }
    &-item {
        position: relative;
        height: 562px;
        @media screen and (max-width: 500px) {
            height: calc(100vh - 78px);
        }
        &__image {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 500px) {
                justify-content: center;
                align-items: center;
                gap: 22px;
            }
            &_wrap {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90.32deg, rgba(0, 0, 0, 0.6) 0.3%, rgba(0, 0, 0, 0.06) 99.76%);
            }
            &_title {
                width: 47%;
                margin: -20px 0 25px;
                @media screen and (max-width: 1140px) {
                    width: 60%;
                }
                @media screen and (max-width: 1024px) {
                    width: 70%;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                @media screen and (max-width: 500px) {
                    margin: 0;
                    text-align: center;
                    font-size: 30px;
                    line-height: 38px;
                }
            }
            &_subtitle {
                width: 47%;
                font-size: 18px;
                line-height: 27px;
                text-transform: uppercase;
                color: $white;
                margin-bottom: 29px;
                display: block;
                @media screen and (max-width: 1140px) {
                    width: 60%;
                }
                @media screen and (max-width: 1024px) {
                    width: 70%;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                @media screen and (max-width: 500px) {
                    text-align: center;
                    margin-bottom: 0;
                }
            }
            &_description {
                width: 47%;
                margin-bottom: 21px;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: $white;
                a {
                    text-decoration: none;
                    color: $white;
                    &:hover {
                        color: $primary;
                    }
                }
                @media screen and (max-width: 1140px) {
                    width: 60%;
                }
                @media screen and (max-width: 1024px) {
                    width: 70%;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                @media screen and (max-width: 500px) {
                    text-align: center;
                    margin-bottom: 0;
                }
            }
            &_button {
                width: 250px;
            }
        }
    }
}