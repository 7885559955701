.single {
    &-content {
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 500px) {
            gap: 30px;
        }
        &__wrap {
            margin: 93px 0 140px;
            @media screen and (max-width: 500px) {
                margin: 35px 0 80px;
            }
        }
        &__item {
            display: flex;
            flex-direction: row;
            gap: 50px;
            @media screen and (max-width: 991px) {
                gap: 20px;
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            &_description {
                width: 100%;
                display: flex;
                flex-direction: column;
                p,
                ul li,
                ol li {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 32px;
                    color: $black-1;
                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
                ul,
                ol {
                    padding-left: 28px;
                }
                strong,
                b {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 32px;
                    color: $primary;
                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
            }
            &_wrapper {
                width: 100%;
                height: 420px;
                border-radius: 10px;
                overflow: hidden;
                @media screen and (max-width: 500px) {
                    height: 230px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &_reverse {
                flex-direction: row-reverse;
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                }
            }
        }
    }
}