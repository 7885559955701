.footer {
    &-wrap {
        padding: 33px 0 30px;
        background: $black-3;
        @media screen and (max-width: 500px) {
            padding: 30px 0;
        }
    }
    &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 40px;
        }
        @media screen and (max-width: 500px) {
            gap: 30px;
        }
    }
    &-logo {
        svg {
            width: 125px;
        }
        @media screen and (max-width: 500px) {
            order: 1;
        }
    }
    &-nav {
        @media screen and (max-width: 500px) {
            order: 3;
        }
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style-type: none;
            gap: 40px;
            @media screen and (max-width: 1024px) {
                gap: 25px;
            }
            @media screen and (max-width: 500px) {
                flex-direction: column;
                gap: 15px;
            }
            li {
                a {
                    font-size: 14px;
                    line-height: 1;
                    text-transform: uppercase;
                    color: $white;
                    text-decoration: none;
                    transition: .25s all;
                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    &:hover {
                        color: $primary;
                        transition: .25s all;
                    }
                    &.active {
                        color: $primary;
                    }
                }
            }
        }
    }
    &-social {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 32px;
        @media screen and (max-width: 500px) {
            order: 2;
        }
    }
    &-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 500px) {
            flex-direction: column-reverse;
        }
        &__item {
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
    &-contacts {
        text-align: right;
        @media screen and (max-width: 500px) {
            text-align: center;
        }
        &__item {
            margin-bottom: 17px;
            @media screen and (max-width: 500px) {
                margin-bottom: 15px;
            }
            a {
                font-size: 16px;
                line-height: 20px;
                color: $white;
                text-decoration: none;
                letter-spacing: 0.01em;
                @media screen and (max-width: 500px) {
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-copyright {
        font-size: 16px;
        line-height: 20px;        
        letter-spacing: 0.03em;
        color: $white;
        @media screen and (max-width: 500px) {
            text-align: center;
            font-size: 12px;
            line-height: 15px;
        }
    }
    &-privacy {
        margin-top: 27px;
        @media screen and (max-width: 500px) {
            text-align: center;
            margin: 22px 0 6px;
        }
        a {
            font-size: 16px;
            line-height: 20px;
            color: $white;
            text-decoration: none;
            letter-spacing: 0.02em;
            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
}