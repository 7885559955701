.popup {
    &-wrap {
        display: none;
        width: 100%;
        max-width: 655px;
        padding: 0;
    }
    &-container {
        padding: 30px 40px;
        @media screen and (max-width: 500px) {
            padding: 65px 25px 30px;
        }
    }
    &-title {
        font-size: 24px;
        line-height: 37px;
        text-transform: uppercase;
        color: $black-1;
        display: block;
        @media screen and (max-width: 500px) {
            text-align: center;
        }
    }
    &-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        margin: 27px 0;
        @media screen and (max-width: 500px) {
            flex-direction: column;
            margin: 20px 0;
        }
        label {
            width: 100%;
            position: relative;
            input {
                background: #F3F3F3;
                border-radius: 30px;
                width: 100%;
                max-width: 100%;
                height: 55px;
                padding: 0 32px 0 80px;
                border: 0;
            }
            svg {
                position: absolute;
                top: 19px;
                left: 34px;
            }
        }
        &_button {
            width: 100%;
            max-width: 210px;
            height: 55px;
            @media screen and (max-width: 500px) {
                max-width: 100%;
            }
        }
    }
    &-privacy {
        font-size: 14px;
        line-height: 21px;
        color: $black-1;
        @media screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 18px;
        }
        a {
            color: $black-1;
            &:hover {
                color: $primary;
            }
        }
    }
}