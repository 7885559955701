.results {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 90px;
    @media screen and (max-width: 1140px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 90px 50px;
    }
    @media screen and (max-width: 991px) {
        gap: 50px 30px;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 100px 10px;
    }
    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 20px;
    }
    &-wrap {
        margin: 140px 0 127px 0;
        @media screen and (max-width: 500px) {
            margin: 90px 0;
        }
    }
    &-item {
        @media screen and (max-width: 500px) {
            text-align: center;
        }
        &:hover {
            svg {
                transform: rotate(360deg);
                transition: .5s all;
            }
        }
    }
    &-icon {
        margin: 0 0 35px 25px;
        @media screen and (max-width: 500px) {
            margin: 0 0 20px 0;
        }
        svg {
            transition: .5s all;
        }
    }
    &-content {
        &__title {
            display: block;
            border-left: 3px solid rgba(0, 173, 22, 0.3);
            padding-left: 25px;
            font-size: 20px;
            line-height: 26px;
            color: $black-1;
            height: 100%;
            max-height: 78px;
            @media screen and (max-width: 500px) {
                font-size: 15px;
                max-height: 100%;
                padding: 0;
                border: none;
            }
            br {
                @media screen and (max-width: 1140px) {
                    display: none;
                }
            }
        }
    }
}