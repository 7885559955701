.activity {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 17px 40px;
    @media screen and (max-width: 1140px) {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
    @media screen and (max-width: 500px) {
        padding: 26px 15px;
        gap: 0;
    }
    &-wrap {
        margin: 127px 0 0 0;
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
    &-title {
        @media screen and (max-width: 500px) {
            margin-bottom: 15px;
        }
    }
    &-item {
        padding: 45px 25px 39px;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: .25s all;
        &:hover {
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
            transition: .25s all;
        }
        @media screen and (max-width: 1140px) {
            padding: 20px;
        }
        @media screen and (max-width: 500px) {
            text-align: center;
            padding: 45px 35px;
        }
    }
    &-icon {
        margin-bottom: 16px;
        height: 47px;
        @media screen and (max-width: 500px) {
            margin-bottom: 26px;
        }
        svg {
            path {
                @media screen and (max-width: 500px) {
                    fill: $primary;
                }
            }
        }
    }
    &-content {
        &__title {
            font-size: 20px;
            line-height: 26px;
            text-transform: uppercase;
            color: $black-1;
            margin-bottom: 21px;
            display: block;
        }
        &__description {
            font-size: 14px;
            line-height: 20px;
            color: $black-1;
        }
    }
    // Мобильная версия
    &-mobile {
        &__wrap {
            display: none;
            @media screen and (max-width: 500px) {
                display: block;
                margin: 90px 0 0;
            }
        }
        &__container {
            padding: 0;
        }
        &__navigation {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: -35px;
            padding: 0 20px;
            &_prev,
            &_next {
                width: 50px;
                height: 50px;
                border: 2px solid #00AD16;
                border-radius: 100%;
                cursor: pointer;
                background: url('/img/cards-slider-arrow.svg') center center no-repeat;
            }
            &_prev {
                transform: rotate(180deg);
            }
        }
        &__pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 15px;
            font-weight: 600;
            font-size: 20px;
            line-height: 1;
            color: $primary;
            span {
                font-weight: 600;
                font-size: 20px;
                line-height: 1;
                color: $primary;
                margin: 0 3px;
            }
        }
    }
}