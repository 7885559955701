.feature {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 52px 0;
    @media screen and (max-width: 1140px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    @media screen and (max-width: 991px) {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 35px 20px;
    }
    &-wrap {
        margin: 70px 0 90px;
        @media screen and (max-width: 500px) {
            margin: 50px 0 35px;
        }
    }
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 500px) {
            gap: 15px;
        }
        &:hover {
            svg {
                transform: rotate(360deg);
                transition: .5s all;
            }
        }
    }
    &-icon {
        margin-bottom: 27px;
        @media screen and (max-width: 500px) {
            height: 80px;
            margin-bottom: 0;
        }
        svg {
            transition: .5s all;
        }
    }
    &-content {
        &__title,
        &__description {
            font-size: 18px;
            line-height: 32px;
            text-transform: uppercase;
            color: $black-2;
            @media screen and (max-width: 500px) {
                text-align: center;
                line-height: 24px;
            }
        }
        &__description {
            text-transform: lowercase;
        }
    }
}