.feedback {
    background: $white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 49px 40px 66px;
    @media screen and (max-width: 500px) {
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        text-align: center;
    }
    &-wrap {
        margin: 120px 0 68px 0;
        @media screen and (max-width: 500px) {
            margin: 90px 0 50px;
        }
    }
    &-title {
        margin-bottom: 18px;
        @media screen and (max-width: 500px) {
            margin-bottom: 20px;
        }
    }
    &-description {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 38px;
        @media screen and (max-width: 500px) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    &-form {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: 17px;
        }
        &__wrap {
            display: flex;
            flex-direction: column;
        }
        label {
            width: 100%;
            input {
                width: 100%;
                height: 55px;
                background: #F3F3F3;
                border-radius: 30px;
                border: none;
                padding: 0 30px;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $black-1;
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                    line-height: 1;
                }
            }
        }
        button {
            width: 100%;
            max-width: 300px;
            @media screen and (max-width: 500px) {
                max-width: 100%;
            }
        }
        &__privacy {
            margin-top: 42px;
            @media screen and (max-width: 500px) {
                margin-top: 22px;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
            }
            a {
                color: inherit;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    &-social {
        margin-top: 55px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        @media screen and (max-width: 500px) {
            margin-top: 30px;
            flex-direction: column;
            gap: 40px;
        }
        &__item {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 30px;
            text-decoration: none;
            cursor: default;
            @media screen and (max-width: 500px) {
                gap: 23px;
            }
            &_icon {
                height: 33px;
                display: flex;
                align-items: center;
                @media screen and (max-width: 500px) {
                    width: 33px;
                    justify-content: center;
                }
            }
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 1;              
                color: $black-1;
                transition: .25s all;
                text-decoration: none;
                &:hover {
                    cursor: pointer;
                    color: $primary;
                    transition: .25s all;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                }
            }
        }
    }
}