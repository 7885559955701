.plan {
    &-wrap {
        margin: 140px 0;
        @media screen and (max-width: 500px) {
            margin: 90px 0;
        }
    }
    #plan {
        width: 100%;
        height: 550px;
        border: none;
        @media screen and (max-width: 500px) {
            height: 400px;
        }
    }
}