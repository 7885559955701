.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 45px 40px;
    @media screen and (max-width: 1140px) {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }
    @media screen and (max-width: 500px) {
        padding: 26px 15px;
        gap: 0;
    }
    &-wrap {
        margin-top: 80px;
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
    &-title {
        margin-bottom: 48px;
        @media screen and (max-width: 500px) {
            margin-bottom: 15px;
        }
    }
    &-item {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        overflow: hidden;
        transition: .25s all;
        &:hover {
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
            transition: .25s all;
        }
        &:hover img {
            filter: grayscale(0.6);
        }
    }
    &-image {
        height: 274px;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        display: block;
        @media screen and (max-width: 500px) {
            height: 230px;
            margin-bottom: 30px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // transition: .25s all;
        }
    }
    &-label {
        position: absolute;
        right: 0;
        top: 0;
        width: max-content;
        padding: 15px 43px;
        border-radius: 0px 20px;
        display: block;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        z-index: 2;
    }
    &-content {
        &__wrap {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 37px 0 43px;
            padding: 0 30px;
            @media screen and (max-width: 1140px) {
                padding: 0 15px;
            }
            @media screen and (max-width: 500px) {
                text-align: center;
            }
            @media screen and (max-width: 500px) {
                margin: 30px 0;
                padding: 0 20px;
            }
        }
        &__title,
        &__price {
            margin-bottom: 20px;
            font-size: 22px;
            line-height: 29px;
            text-transform: uppercase;
            text-decoration: none;
            @media screen and (max-width: 500px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        &__title {
            color:$black-1;
            transition: .25s all;
            flex: 1;
            &:hover {
                color: $primary;
                transition: .25s all;
            }
        }
        &__price {
            color: $primary;
        }
        &__list {
            list-style-type: none;
            flex: 1;
            &_item {
                font-size: 16px;
                line-height: 27px;
                margin-bottom: 6px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &__button {
            margin-top: 32px;
            width: 100%;
            height: 50px;
        }
    }
    // Мобильная версия
    &-mobile {
        &__wrap {
            display: none;
            @media screen and (max-width: 500px) {
                display: block;
                margin-top: 47px;
            }
        }
        &__container {
            padding: 0;
        }
        &__navigation {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: -35px;
            padding: 0 20px;
            &_prev,
            &_next {
                width: 50px;
                height: 50px;
                border: 2px solid #00AD16;
                border-radius: 100%;
                cursor: pointer;
                background: url('/img/cards-slider-arrow.svg') center center no-repeat;
            }
            &_prev {
                transform: rotate(180deg);
            }
        }
        &__pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 15px;
            font-weight: 600;
            font-size: 20px;
            line-height: 1;
            color: $primary;
            span {
                font-weight: 600;
                font-size: 20px;
                line-height: 1;
                color: $primary;
                margin: 0 3px;
            }
        }
    }
}