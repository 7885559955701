.typical {
    &-content {
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 500px) {
            gap: 40px;
        }
        &__wrap {
            margin: 93px 0 140px;
            @media screen and (max-width: 768px) {
                margin: 40px 0 50px;
            }
            @media screen and (max-width: 500px) {
                margin: 26px 0 60px;
            }
        }
        &__item {
            display: flex;
            flex-direction: row;
            gap: 50px;
            @media screen and (max-width: 991px) {
                gap: 20px;
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            &_description {
                width: 100%;
                display: flex;
                flex-direction: column;
                h1 {
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 53px;
                    color: $black-1;
                    text-transform: uppercase;
                    margin-bottom: 35px;
                    @media screen and (max-width: 991px) {
                        font-size: 36px;
                    }
                    @media screen and (max-width: 500px) {
                        margin-bottom: 26px;
                        font-size: 30px;
                        line-height: 38px;
                    }
                }
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 33px;
                    color: $black-1;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                    @media screen and (max-width: 991px) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 16px;
                    }
                }
                p,
                ul li,
                ol li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black-1;
                    @media screen and (max-width: 500px) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
                ul,
                ol {
                    padding-left: 28px;
                }
                strong,
                b {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black-1;
                }
            }
            &_wrapper {
                width: 100%;
                height: 420px;
                overflow: hidden;
                @media screen and (max-width: 500px) {
                    height: 220px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
            &_reverse {
                flex-direction: row-reverse;
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                }
            }
        }
    }
}