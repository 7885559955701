.contacts {
    display: flex;
    flex-direction: row;
    gap: 47px;
    @media screen and (max-width: 500px) {
        flex-direction: column;
        gap: 50px;
    }
    &-wrap {
        margin: 73px 0;
        @media screen and (max-width: 768px) {
            margin: 40px 0;
        }
        @media screen and (max-width: 500px) {
            margin: 26px 0 60px;
        }
    }
    &-title {
        margin-bottom: 0;
        color: $black-1;
        @media screen and (max-width: 500px) {
            font-size: 28px;
            line-height: 38px;
        }
    }
    &-form {
        margin-top: 37px;
        width: 30%;
        @media screen and (max-width: 768px) {
            width: 50%;
        }
        @media screen and (max-width: 500px) {
            margin-top: 26px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        label {
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            input,
            textarea {
                width: 100%;
                height: 55px;                
                border: 1px solid #E3E3E3;
                border-radius: 10px;
                padding: 0 30px;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $black-3;
                margin-bottom: 18px;
            }
            textarea {
                height: 92px;
                padding: 15px 30px;
                resize: none;
                margin-bottom: 0;
            }
        }
        &__button {
            margin-top: 27px;
            height: 55px;
            width: 100%;
            max-width: 260px;
        }
        &__privacy {
            margin-top: 15px;
            font-size: 12px;
            line-height: 18px;
            color: #868686;
        }
    }
    &-info {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 45px;
        @media screen and (max-width: 768px) {
            width: 50%;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
            text-align: center;
            align-items: center;
        }
        @media screen and (max-width: 500px) {
            gap: 30px;
        }
    }
    &-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 70px;
        @media screen and (max-width: 1140px) {
            gap: 30px;
        }
        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }
        &__item {
            @media screen and (max-width: 500px) {
                padding: 0 40px;
            }
            &_icon {
                height: 38px;
                display: flex;
                align-items: center;
                margin-bottom: 26px;
                @media screen and (max-width: 500px) {
                    justify-content: center;
                }
            }
            &_title {
                font-size: 18px;
                line-height: 23px;
                text-transform: uppercase;
                color: $black-1;
                margin-bottom: 14px;
                display: block;
            }
            &_description {
                font-size: 16px;
                line-height: 21px;
                color: $black-1;
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
    &-requisites {
        display: flex;
        flex-direction: column;
        &__icon {
            height: 38px;
            display: flex;
            align-items: center;
            margin-bottom: 26px;
            @media screen and (max-width: 500px) {
                justify-content: center;
            }
        }
        &__title {
            font-size: 18px;
            line-height: 23px;
            text-transform: uppercase;
            color: $black-1;
            margin-bottom: 14px;
        }
        &__description {
            font-size: 16px;
            line-height: 21px;
            color: $black-1;
            @media screen and (max-width: 500px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    &-map {
        width: 100%;
        height: 510px;
        @media screen and (max-width: 768px) {
            height: auto;
        }
        &__wrap {
            margin-top: 73px;
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
    }
}